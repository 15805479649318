import { dispatch } from 'vuex-pathify';
import { GeoJSON, MVT, KML } from 'ol/format';
import { defaultEpsg } from '@/assets/js/variables';
import { Vector as VectorSource, VectorTile as VectorTileSource } from 'ol/source';
import { getFlatGroupsLayers } from '@/assets/js/utility';
import { getDistance, getLength } from 'ol/sphere';
import { transform } from 'ol/proj';
import turfDestination from '@turf/destination';
import Feature from 'ol/Feature';
import RenderFeature from 'ol/render/Feature';
import { Point, Polygon, LineString, MultiPolygon, MultiLineString } from 'ol/geom';
import { getCenter } from 'ol/extent';
import geodesic from 'geographiclib-geodesic';
import { saveFile } from '@/assets/js/utility';
import axios from 'axios';

const prepareDrawnGeometry = feature => {
  const geoJsonFeature = new GeoJSON().writeFeatureObject(feature, {
    featureProjection: defaultEpsg || 'EPSG:4326',
    dataProjection: defaultEpsg || 'EPSG:4326',
  });
  return {
    ...geoJsonFeature.geometry,
    crs: {
      type: 'name',
      properties: {
        name: defaultEpsg || 'EPSG:4326',
      },
    },
  };
};

const prepareDrawnFeature = feature => {
  const geoJsonFeature = new GeoJSON().writeFeatureObject(feature, {
    featureProjection: defaultEpsg || 'EPSG:4326',
    dataProjection: defaultEpsg || 'EPSG:4326',
  });
  return {
    ...geoJsonFeature,
    geometry: {
      ...geoJsonFeature.geometry,
      crs: {
        type: 'name',
        properties: {
          name: defaultEpsg || 'EPSG:4326',
        },
      },
    },
  };
};

const prepareDrawnMultiGeometry = features => {
  let type = '';
  const coordinates = features.map((feature, index) => {
    const geoJsonFeature = new GeoJSON().writeFeatureObject(feature, {
      featureProjection: defaultEpsg || 'EPSG:4326',
      dataProjection: defaultEpsg || 'EPSG:4326',
    });
    if (index === 0) {
      const geojsonFeatureType = geoJsonFeature.geometry.type;
      type = geojsonFeatureType.startsWith('Multi') ? geojsonFeatureType : `Multi${geojsonFeatureType}`;
    }
    return geoJsonFeature.geometry.coordinates;
  });
  return {
    type,
    coordinates,
    crs: {
      type: 'name',
      properties: {
        name: defaultEpsg || 'EPSG:4326',
      },
    },
  };
};

const saveGeometryToFile = (type, fileName, featureCollection) => {
  const contentString =
    type === 'kml'
      ? `<?xml version="1.0" encoding="utf-8" ?>${new KML().writeFeatures(
          new GeoJSON().readFeatures(featureCollection),
          {
            featureProjection: defaultEpsg || 'EPSG:4326',
            dataProjection: 'EPSG:4326',
          }
        )}`
      : JSON.stringify(featureCollection);
  const file = new Blob([contentString], {
    type: `${type === 'kml' ? 'text/xml' : 'application/geo+json'};charset=utf-8;`,
  });
  saveFile(URL.createObjectURL(file), fileName);
};

const getServiceLayerEmbedCredentials = url => {
  const regex = /https:\/\/([^:]+):([^@]+)@/;
  const matches = url.match(regex);
  if (matches?.[1] && matches?.[2]) {
    return {
      username: matches[1],
      password: matches[2],
    };
  } else return undefined;
};

const serviceLayerWithCredentialsLoader = (tile, src, credentials) => {
  const client = new XMLHttpRequest();

  client.open('GET', src);
  client.responseType = 'arraybuffer';
  client.setRequestHeader('Authorization', 'Basic ' + btoa(credentials.username + ':' + credentials.password));

  client.onload = function () {
    const arrayBufferView = new Uint8Array(this.response);
    const blob = new Blob([arrayBufferView], { type: 'image/png' });
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(blob);
    tile.getImage().src = imageUrl;
  };

  client.send();
};

const defaultMvtSourceLoader = (
  tile,
  layerId,
  { filters, styleAttributes, cache, databox = false } = {},
  forceNonCache = false,
  webWorkerToken = null
) => {
  tile.setLoader(async (extent, resolution, featureProjection) => {
    let responseArrayBuffer;
    const layer = [true, false].includes(cache)
      ? null
      : getFlatGroupsLayers(await dispatch('layers/getProjectLayers')).layers.find(l => l.layer_id == layerId);
    const isCacheLayer = cache ?? (layer?.cache || false);
    if (isCacheLayer && !forceNonCache) {
      const [z, x, y] = tile.tileCoord;
      const r = await fetch(`${import.meta.env.VUE_APP_API_URL}/vtiles/${layerId}/${z}/${x}/${y}.pbf`);
      responseArrayBuffer = await r.arrayBuffer();
    } else if (databox) {
      const [z, x, y] = tile.tileCoord;
      const url = layer.url.replaceAll('{z}', z).replaceAll('{x}', x).replaceAll('{y}', y);
      const r = await fetch(url);
      responseArrayBuffer = await r.arrayBuffer();
    } else {
      let params = {
        layerId,
        features_filter: filters,
        envelope: extent,
        attributes: styleAttributes,
        zxy: tile.tileCoord,
      };
      const r = webWorkerToken
        ? await axios.post(
            `${import.meta.env.VUE_APP_API_URL}/mvt_service/${layerId}`,
            {
              data: {
                ...params,
                use_cache: true,
              },
            },
            {
              responseType: 'arraybuffer',
              headers: {
                'X-Access-Token': webWorkerToken,
                'X-Response-SRID': 'EPSG:3857',
              },
            }
          )
        : await dispatch('layers/getTile', params);
      responseArrayBuffer = r.data;
    }
    const features = tile.getFormat().readFeatures(responseArrayBuffer, {
      extent,
      featureProjection,
    });
    tile.setFeatures(features);
  });
};

const defaultMvtLayerSource = (
  layer,
  { loader = defaultMvtSourceLoader, styleAttributes, filters, webWorkerToken, isCacheLayer }
) => {
  const { id, geometry_type, direction_arrows_visible, cache } = layer;
  const maxZoomLayer = import.meta.env.VUE_APP_MAX_ZOOM_LAYER ? parseInt(import.meta.env.VUE_APP_MAX_ZOOM_LAYER) : 16;
  const maxZoomCacheLayer = import.meta.env.VUE_APP_MAX_ZOOM_CACHE_LAYER
    ? parseInt(import.meta.env.VUE_APP_MAX_ZOOM_CACHE_LAYER)
    : 16;
  const maxZoom = cache ? maxZoomCacheLayer : maxZoomLayer;
  const featureClass =
    direction_arrows_visible && ['linestring', 'multilinestring'].includes(geometry_type) ? Feature : RenderFeature;
  return new VectorTileSource({
    cacheSize: 16,
    format: new MVT({
      featureClass,
    }),
    url: `${import.meta.env.VUE_APP_API_URL}/layers/features_layers/${id}/mvt/{z}/{x}/{y}`,
    projection: defaultEpsg,
    tileLoadFunction: function (tile) {
      loader(tile, id, { filters, styleAttributes, cache: isCacheLayer ?? cache }, false, webWorkerToken);
    },
    maxZoom,
  });
};

const databoxMvtLayerSource = (layer, { loader = defaultMvtSourceLoader } = {}) => {
  const { id, url, parameters = {} } = layer;
  return new VectorTileSource({
    cacheSize: 16,
    format: new MVT(),
    url,
    projection: defaultEpsg,
    tileLoadFunction: function (tile) {
      loader(tile, id, { databox: true });
    },
    ...(parameters.min_zoom ? { minZoom: parameters.min_zoom } : {}),
    ...(parameters.max_zoom ? { maxZoom: parameters.max_zoom } : {}),
  });
};

const createVectorSource = (features, name = 'newVectorSource') => {
  return new VectorSource({
    features: new GeoJSON().readFeatures(
      {
        type: 'FeatureCollection',
        features,
      },
      {
        featureProjection: defaultEpsg || 'EPSG:4326',
        dataProjection: defaultEpsg || 'EPSG:4326',
      }
    ),
    name,
  });
};

const writeGeometryToGeoJSON = geometry => {
  return {
    ...new GeoJSON().writeGeometryObject(geometry, {
      dataProjection: defaultEpsg || 'EPSG:4326',
      featureProjection: defaultEpsg || 'EPSG:4326',
    }),
    crs: {
      type: 'name',
      properties: {
        name: defaultEpsg || 'EPSG:4326',
      },
    },
  };
};

const getCircleGeometryRadius = ({ geometry, startCoordinate, endCoordinate } = {}, epsg = defaultEpsg) => {
  return getDistance(
    transform(geometry ? geometry.getFirstCoordinate() : startCoordinate, epsg, 'EPSG:4326'),
    transform(geometry ? geometry.getLastCoordinate() : endCoordinate, epsg, 'EPSG:4326')
  );
};

/**
 * Calculate geodesic area of the geometry using geographiclib-geodesic library.
 * Coordinates have to be oriented counter-clockwised
 * Otherwise geolib will return area for the rest of the Earth
 */
const getGeodesicArea = (geometry, epsg = defaultEpsg) => {
  const geometryType = geometry.getType();
  if (!['Polygon', 'MultiPolygon'].includes(geometryType)) return 0;
  let polygons = [];
  if (geometryType === 'Polygon') polygons = [geometry];
  else if (geometryType === 'MultiPolygon') polygons = geometry.getPolygons();
  const geod = geodesic.Geodesic.WGS84;
  const area = polygons.reduce((polygonsArea, currentPolygon) => {
    // Iterate over polygons and calculate area for each polygon
    const currentPolygonWgsGeom = currentPolygon.clone().transform(epsg, 'EPSG:4326');
    const currentPolygonArea = currentPolygonWgsGeom
      .getCoordinates(true)
      .reduce((polygonArea, polygonPart, polygonPartIndex) => {
        // Iterate over polygon parts and calculate area for each part
        const geolibPolygon = geod.Polygon();
        // Reverse holes to be oriented counter-clockwised
        (polygonPartIndex ? polygonPart.reverse() : polygonPart).forEach(([lon, lat]) =>
          geolibPolygon.AddPoint(lat, lon)
        );
        const polygonPartArea = geolibPolygon.Compute()?.area ?? 0;
        // If polygon part is first part of the polygon (proper surface), add its area to the polygon area
        if (polygonPartIndex === 0) polygonArea = polygonArea + polygonPartArea;
        // Else if polygon part is not first part of the polygon (hole in surface), subtract its area from the polygon area
        else polygonArea = polygonArea - polygonPartArea;
        return polygonArea;
      }, 0);
    return polygonsArea + currentPolygonArea;
  }, 0);
  return area;
};

const formatArea = (geometry, epsg = defaultEpsg) => {
  const area = getGeodesicArea(geometry, epsg);
  if (area > 1000000) {
    return `${(area / 1000000).toFixed(2)} km2`;
  } else if (area > 10000) {
    return `${(area / 10000).toFixed(2)} ha`;
  }
  return `${area.toFixed(2)} m2`;
};

const parseLength = meters => {
  if (meters > 1000) {
    return `${(meters / 1000).toFixed(2)} km`;
  }
  return `${meters.toFixed(2)} m`;
};

const formatLength = geometry => {
  const length = getLength(geometry);
  return parseLength(length);
};

const getFormatCircleRadius = radius => {
  if (radius > 1000) {
    return `${(radius / 1000).toFixed(2)} km`;
  }
  return `${radius.toFixed(2)} m`;
};

const getFormatCircleArea = radius => {
  const area = Math.PI * radius * radius;
  if (area > 1000000) {
    return `${(area / 1000000).toFixed(2)} km2`;
  } else if (area > 10000) {
    return `${(area / 10000).toFixed(2)} ha`;
  }
  return `${area.toFixed(2)} m2`;
};

const getFormatCircleLength = radius => {
  const length = 2 * Math.PI * radius;
  if (length > 1000) {
    return `${(length / 1000).toFixed(2)} km`;
  }
  return `${length.toFixed(2)} m`;
};

const getDistanceBetweenCoords = (coordA, coordB, epsg = defaultEpsg) => {
  return getDistance(transform(coordA, epsg, 'EPSG:4326'), transform(coordB, epsg, 'EPSG:4326'));
};

const getDestinationCoords = (coordA, azimuth, length, epsg = defaultEpsg) => {
  const coordAGeoJson = {
    type: 'Feature',
    geometry: {
      coordinates: transform(coordA, epsg, 'EPSG:4326'),
      type: 'Point',
    },
  };
  const coordBGeoJson = turfDestination(coordAGeoJson, length / 1000, azimuth);
  const coordB = transform(coordBGeoJson.geometry.coordinates, 'EPSG:4326', epsg);
  return coordB;
};

const getAzimuthOfCoords = (coordA, coordB, epsg = defaultEpsg) => {
  const distanceBetweenCords = getDistanceBetweenCoords(coordA, coordB);
  const coordAGeoJson = {
    type: 'Feature',
    geometry: {
      coordinates: transform(coordA, epsg, 'EPSG:4326'),
      type: 'Point',
    },
  };
  const bearingBetweenCoords = getBearingBetweenCoords(coordA, coordB);
  const azimuths = [90 - bearingBetweenCoords, 270 - bearingBetweenCoords];
  const coordsC = azimuths.map(azimuth => {
    const coordCGeoJson = turfDestination(coordAGeoJson, distanceBetweenCords / 1000, azimuth);
    const coordC = transform(coordCGeoJson.geometry.coordinates, 'EPSG:4326', epsg);
    return { coord: coordC, distance: getDistanceBetweenCoords(coordC, coordB, epsg), azimuth };
  });
  const result = coordsC.reduce((total, current) => {
    return current.distance < total.distance ? current : total;
  });
  return result.azimuth;
};

const getPolygonInteriorPoint = geometry => {
  return geometry.getInteriorPoint().getCoordinates().splice(0, 2);
};

const getGeometryCentroid = (geometry, byBbox) => {
  switch (geometry.constructor) {
    /** Simple geometry types */
    case Point:
      return geometry.getCoordinates();
    case LineString:
      return byBbox ? getCenter(geometry.getExtent()) : geometry.getCoordinateAt(0.5);
    case Polygon:
      return byBbox ? getCenter(geometry.getExtent()) : getPolygonInteriorPoint(geometry);
    /** Complex geometry types */
    case MultiPolygon: {
      const polygonsArray = geometry.getPolygons();
      return polygonsArray.length === 1
        ? byBbox
          ? getCenter(polygonsArray[0].getExtent())
          : getPolygonInteriorPoint(polygonsArray[0])
        : getCenter(geometry.getExtent());
    }
    case MultiLineString: {
      const lineStringsArray = geometry.getLineStrings();
      return lineStringsArray.length === 1
        ? byBbox
          ? getCenter(lineStringsArray[0].getExtent())
          : lineStringsArray[0].getCoordinateAt(0.5)
        : getCenter(geometry.getExtent());
    }
    default:
      return getCenter(geometry.getExtent());
  }
};

const getBearingBetweenCoords = (coordA, coordB) => {
  const rotationRadians = Math.atan((coordB[1] - coordA[1]) / (coordB[0] - coordA[0]));
  const rotationDegrees = (rotationRadians * 180) / Math.PI;
  return rotationDegrees;
};

const getPointOnLineExtension = (coordA, coordB, distance, epsg = defaultEpsg) => {
  const distanceBetweenCords = getDistanceBetweenCoords(coordA, coordB);
  const coordAGeoJson = {
    type: 'Feature',
    geometry: {
      coordinates: transform(coordA, epsg, 'EPSG:4326'),
      type: 'Point',
    },
  };
  const bearingBetweenCoords = getBearingBetweenCoords(coordA, coordB);
  const azimuths = [90 - bearingBetweenCoords, 270 - bearingBetweenCoords];
  const coordsC = azimuths.map(azimuth => {
    const coordCGeoJson = turfDestination(coordAGeoJson, (distanceBetweenCords + distance) / 1000, azimuth);
    const coordC = transform(coordCGeoJson.geometry.coordinates, 'EPSG:4326', epsg);
    return { coord: coordC, distance: getDistanceBetweenCoords(coordC, coordB, epsg) };
  });
  const result = coordsC.reduce((total, current) => {
    return current.distance < total.distance ? current : total;
  });
  return result.coord;
};

export {
  prepareDrawnGeometry,
  prepareDrawnFeature,
  prepareDrawnMultiGeometry,
  defaultMvtSourceLoader,
  defaultMvtLayerSource,
  databoxMvtLayerSource,
  createVectorSource,
  writeGeometryToGeoJSON,
  getCircleGeometryRadius,
  getDistanceBetweenCoords,
  getBearingBetweenCoords,
  getPointOnLineExtension,
  formatArea,
  formatLength,
  parseLength,
  saveGeometryToFile,
  getGeodesicArea,
  getFormatCircleArea,
  getFormatCircleLength,
  getFormatCircleRadius,
  getAzimuthOfCoords,
  getDestinationCoords,
  getGeometryCentroid,
  getServiceLayerEmbedCredentials,
  serviceLayerWithCredentialsLoader,
};
