import { memoizer } from '@/assets/js/utility';

import { sync, get } from 'vuex-pathify';
import { inRange } from 'lodash';
import { Style, Fill, Stroke, Circle, Icon, Text } from 'ol/style';
import { getCenter } from 'ol/extent';
import { LineString, MultiLineString, Point, Polygon, MultiPolygon } from 'ol/geom';
import {
  getCircleGeometryRadius,
  getFormatCircleArea,
  getFormatCircleLength,
  getFormatCircleRadius,
  formatLength,
  getGeometryCentroid,
} from '@/assets/js/mapUtils';

import stylePreviewUtilities from '@/mixins/stylePreviewUtilities';

import {
  highlitedColor,
  selectedColor,
  haloColorHighlited,
  haloColorSelected,
  getText,
  getStroke,
  getPointStyle,
  getLineStyle,
  getCircle,
  isFeatureHighlitedOrSelected,
  getFeatureStyle,
} from '@/assets/js/stylesUtils';

export default {
  mixins: [stylePreviewUtilities],
  computed: {
    isModificationInteractionActive: sync('edit/isModificationInteractionActive'),
    metadata: sync('layers/metadata'),
    cacheFilterFeatures: get('map/cacheFilterFeatures'),
    erroredFeatures: get('map/erroredFeatures'),
    selectedFeatures: get('map/selectedFeatures'),
    highlitedFeatures: get('map/highlitedFeatures'),
    measuredFeatures: get('map/measuredFeatures'),
    currentLayerDataSourceName: get('layers/currentLayer@data_source_name'),
    idPropertyName: get('layers/metadata@:currentLayerDataSourceName.attributes_schema.id_name'),
    modulesPermissions: get('users/toolsPermissions'),
    isDirectionArrowsModuleEnabled: get('admin/additionalModules@DIRECTION_ARROWS_VISIBLE.enabled'),
    isDirectionArrowsVisible() {
      return this.isDirectionArrowsModuleEnabled && this.modulesPermissions['DIRECTION_ARROWS_VISIBLE'].main_value > 0;
    },
    netType() {
      return this.$route.params.netType;
    },
  },
  methods: {
    /** */
    getFeatureStyle(
      feature,
      style,
      type,
      labelsVisible,
      layer,
      directionArrowsVisible,
      { value, parameter } = {},
      selectedColors = {},
      cacheLayer = false
    ) {
      return getFeatureStyle({
        feature,
        style,
        type,
        labelsVisible,
        layer,
        directionArrowsVisible,
        styleParameters: { value, parameter },
        selectedColors,
        cacheLayer,
        idPropertyName: this.idPropertyName,
        zoom: this.zoom,
        isDirectionArrowsVisible: this.isDirectionArrowsVisible,
        highlitedFeatures: this.highlitedFeatures,
        selectedFeatures: this.selectedFeatures,
        erroredFeatures: this.erroredFeatures,
        measuredFeatures: this.measuredFeatures,
        cacheFilterFeatures: this.cacheFilterFeatures,
      });
    },
    getLength(value, unit) {
      const dict = {
        m: 1,
        km: 1000,
        miles: 1609.344,
      };
      return +value * dict[unit];
    },
    getCongestionManholesText({ value, labelsStyle }) {
      const text = value || value === 0 ? `${parseFloat(value.toFixed(2))}` : this.$i18n.t('default.noData');
      return new Style({
        text: getText({ text, labelsStyle }),
      });
    },
    getChoroplethMapFeatureStyle(f, data, layer) {
      const { id } = f.getProperties();
      const { ranges, rangesColors, values } = data;
      const value = values[id];

      let color;
      if (isFeatureHighlitedOrSelected(id, layer)) {
        color = highlitedColor;
      } else {
        color = this.getValueInRange(value, ranges, rangesColors);
      }
      let style = this.styleCache[`${color}ChoroplethMap`];
      if (!style) {
        style = this.styleCache[`${color}ChoroplethMap`] = new Style({
          fill: new Fill({
            color,
          }),
          stroke: new Stroke({
            color: 'black',
            width: 1,
          }),
        });
      }
      return style;
    },
    getModelingLayerFeatureStyle(f, data, resolution, layer, labelsVisible, featureStyle) {
      const { id, st_length, label } = f.getProperties();
      const type = f.getType();
      const { name, ranges, unit, abs } = data;
      const parameter = f.get(name);
      let parameterValue = abs
        ? Math.abs(this.getParameterValue(type, id, name, parameter))
        : this.getParameterValue(type, id, name, parameter);
      const zoom = type === 'LineString' && this.getCacheZoomForResolution(resolution);
      const mapUnit = this.map.getView().getProjection().getUnits();
      const metersLength = this.getLength(st_length, mapUnit);
      let color;
      let haloColor;
      let dash = [];
      let size = this.getValueInRange(parameterValue, ranges, featureStyle.sizes);
      const selectedOrHighlited = isFeatureHighlitedOrSelected(id, layer);
      if (selectedOrHighlited) {
        color = selectedOrHighlited === 'highlited' ? highlitedColor : selectedColor;
        haloColor = selectedOrHighlited === 'highlited' ? haloColorHighlited : haloColorSelected;
      } else {
        color = this.getValueInRange(parameterValue, ranges, featureStyle.colors);
      }
      const hasLabels = labelsVisible && this.zoom > 16;
      let styleCacheAppendix = '';
      if (featureStyle.dashes) {
        dash = this.getValueInRange(parameterValue, ranges, featureStyle.dashes);
        styleCacheAppendix = `-${dash}`;
      }
      let style =
        this.styleCache[`${color}-${label}-${parameterValue}-${hasLabels}-${unit}-${size}${styleCacheAppendix}`];
      if (!style) {
        style = new Style({
          image: getCircle({
            circleColor: color,
            fillOpacity: 1,
            radius: size,
          }),
          stroke: getStroke({
            fill: color,
            opacity: 1,
            width: size,
            dash,
          }),
        });
        if (hasLabels) {
          style.setText(
            getText({
              text: `${label || ''} (${
                parameterValue && parameterValue != '0' ? parameterValue.toFixed(4) : 0
              } ${unit})`,
              labelsStyle: {
                'font-size': selectedOrHighlited ? 15 : 13,
                'font-weight': 'normal',
                'stroke-visible': true,
                'stroke-width': selectedOrHighlited ? 6 : 3,
                'offset-x': 0,
                'offset-y': -20,
                'font-color': color,
                'stroke-color': this.$_isHexColorLight(color) ? '#303030' : '#ffffff',
              },
            })
          );
        }
        this.styleCache[`${color}-${label}-${parameterValue}-${hasLabels}-${unit}-${size}${styleCacheAppendix}`] =
          style;
      }
      return [
        ...(selectedOrHighlited ? [this.getModelingHighlitedHaloStyle(type, haloColor, style)] : []),
        style,
        ...(type === 'LineString' && zoom > 16 && metersLength / resolution > 100
          ? [
              this.getArrow(
                f,
                id,
                color,
                this.netType === 'water' ? (this.getParameterValue(type, id, 'flow', f.get('flow')) > 0 ? 0 : 1) : 0,
                this.getArrowScale(zoom)
              ),
            ]
          : []),
      ];
    },
    getModelingHighlitedHaloStyle(type, haloColor) {
      return type === 'LineString'
        ? getLineStyle({
            lineColor: haloColor,
            fillOutlineOpacity: 1,
            lineWidth: 10,
          })
        : getPointStyle({
            circleColor: haloColor,
            fillOpacity: 1,
            radius: 7.5,
          });
    },
    getParameterValue(type, id, name, parameter) {
      const hash = this.$_getHash(id + type + name);
      let parsedParameterValues = this.valuesCache[hash];
      if (!parsedParameterValues) {
        parsedParameterValues = this.valuesCache[hash] = JSON.parse(parameter);
        return parsedParameterValues[this.currentTimestepIndex];
      }
      return parsedParameterValues[this.currentTimestepIndex];
    },
    getValueInRange(value, ranges, dataSet, noDataColor = false) {
      if (value === undefined || value === null) {
        return noDataColor;
      }
      const infinityRanges = [-Infinity, ...ranges, Infinity];
      for (let i = 0; i < infinityRanges.length; i++) {
        if (i < infinityRanges.length) {
          if (inRange(value, infinityRanges[i], infinityRanges[i + 1])) {
            return dataSet[i];
          }
        }
      }
    },
    getArrowScale(zoom) {
      if (zoom < 15) {
        return 0.55;
      } else if (zoom < 18) {
        return 0.7;
      }
      return 0.85;
    },
    getCacheZoomForResolution(resolution) {
      let zoom = this.zoomForResolution[resolution];
      if (!zoom) {
        zoom = this.zoomForResolution[resolution] = this.map.getView().getZoomForResolution(resolution);
      }
      return zoom;
    },
    getArrow(feature, id, color, isReversed, arrowScale) {
      const { center_x, center_y, center_azimuth } = feature.getProperties();
      const rotation = isReversed ? center_azimuth + Math.PI : center_azimuth;
      return this.getArrowStyle([center_x, center_y], id, color, arrowScale, rotation);
    },
    getArrowStyle(extentCenter, id, color, scale, rotation) {
      const hash = this.$_getHash(id + color + scale + rotation);
      let arrow = this.arrowStyles[hash];
      if (!arrow) {
        arrow = this.arrowStyles[hash] = new Style({
          geometry: new Point(extentCenter),
          image: new Icon({
            src: '/arrow.svg',
            rotateWithView: true,
            color,
            scale,
            rotation,
          }),
        });
      }
      return arrow;
    },
    getIntersectMeasurementStyles1({ geometry } = {}) {
      if (!(geometry instanceof Polygon) && !(geometry instanceof MultiPolygon)) {
        return [];
      }
      return [
        new Style({
          fill: new Fill({
            color: 'rgba(237, 234, 183, 0.5)',
          }),
        }),
      ];
    },
    getIntersectMeasurementStyles2({ feature, geometry } = {}) {
      if (!(geometry instanceof Polygon) && !(geometry instanceof MultiPolygon)) {
        return [];
      }
      const unionArea = feature.get('union_area');
      return [
        new Style({
          geometry: geometry.getInteriorPoint(),
          text: new Text({
            backgroundFill: new Fill({
              color: 'rgba(255, 255, 255, 0.6)',
            }),
            text: this.getPolygonMeasurementLabel(geometry, unionArea),
            font: 'bold 13px sans-serif',
            fill: new Fill({
              color: 'black',
            }),
            placement: 'point',
            stroke: new Stroke({
              color: 'transparent',
              width: 0,
            }),
            offsetX: 0,
            offsetY: 0,
            padding: [5, 5, 5, 5],
          }),
        }),
      ];
    },
    getAreaMeasurementStyles({ geometry, simple = false } = {}) {
      if (!(geometry instanceof Polygon) && !(geometry instanceof MultiPolygon)) {
        return [];
      }
      const styles = [
        ...(simple
          ? []
          : [
              new Style({
                fill: new Fill({
                  color: 'rgba(237, 234, 183, 0.35)',
                }),
                stroke: new Stroke({
                  color: 'rgba(0, 0, 0, 0.5)',
                  lineDash: [10, 10],
                  width: 2,
                }),
              }),
            ]),
      ];
      (geometry instanceof Polygon ? [geometry] : geometry.getPolygons()).forEach(singlePolygonGeom => {
        const [coordinates] = singlePolygonGeom.getCoordinates();
        const line = new LineString(coordinates);
        line.forEachSegment((start, end) => {
          const line = new LineString([start, end]);
          const length = formatLength(line);
          styles.unshift(
            new Style({
              geometry: line,
              ...(simple
                ? {}
                : {
                    stroke: new Stroke({
                      color: 'rgba(0, 0, 0, 0.5)',
                      lineDash: [10, 10],
                      width: 2,
                    }),
                  }),
              text: new Text({
                text: length,
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: this.$_colors.font,
                }),
                stroke: new Stroke({
                  color: 'white',
                  width: 4,
                }),
                placement: 'line',
                offsetX: 20,
                offsetY: 20,
              }),
            })
          );
        });

        if (!simple && coordinates?.length > 3) {
          const ceontroid = getGeometryCentroid(singlePolygonGeom);
          const point = new Point(ceontroid);
          styles.unshift(
            new Style({
              geometry: point,
              image: new Icon({
                src: '/cursormove.svg',
              }),
            })
          );
        }
        if (coordinates.length > 3) {
          styles.push(
            new Style({
              geometry: singlePolygonGeom.getInteriorPoint(),
              text: new Text({
                backgroundFill: new Fill({
                  color: 'rgba(0, 0, 0, 0.6)',
                }),
                text: this.getPolygonMeasurementLabel(singlePolygonGeom),
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: 'white',
                }),
                placement: 'point',
                stroke: new Stroke({
                  color: 'transparent',
                  width: 0,
                }),
                offsetX: 0,
                offsetY: simple ? 0 : 40,
                padding: [5, 5, 5, 5],
              }),
            })
          );
        }
      });
      return styles;
    },
    getLengthMeasurementStyles({ geometry, simple = false } = {}) {
      if (!(geometry instanceof LineString) && !(geometry instanceof MultiLineString)) {
        return [];
      }
      const styles = [];
      (geometry instanceof LineString ? [geometry] : geometry.getLineStrings()).forEach(singleLinestringGeom => {
        if (singleLinestringGeom.getCoordinates().length > 2) {
          const ceontroid = getGeometryCentroid(singleLinestringGeom);
          const point = new Point(ceontroid);
          styles.unshift(
            new Style({
              geometry: point,
              image: new Icon({
                src: '/cursormove.svg',
              }),
            })
          );
          styles.push(
            new Style({
              geometry: new Point(singleLinestringGeom.getLastCoordinate()),
              text: new Text({
                backgroundFill: new Fill({
                  color: 'rgba(0,0,0,.7)',
                }),
                text: formatLength(singleLinestringGeom),
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: 'white',
                }),
                placement: 'point',
                stroke: new Stroke({
                  color: 'transparent',
                  width: 3,
                }),
                offsetX: 0,
                offsetY: 30,
                padding: [5, 5, 5, 5],
              }),
            })
          );
        }
        singleLinestringGeom.forEachSegment((start, end) => {
          const line = new LineString([start, end]);
          styles.push(
            new Style({
              geometry: line,
              ...(simple
                ? {}
                : {
                    fill: new Fill({
                      color: this.$_colors.font,
                    }),
                    stroke: new Stroke({
                      color: 'rgba(0, 0, 0, 0.8)',
                      lineDash: [10, 10],
                      width: 2,
                    }),
                  }),
              text: new Text({
                text: formatLength(line),
                font: 'bold 13px sans-serif',
                fill: new Fill({
                  color: this.$_colors.font,
                }),
                placement: 'line',
                stroke: new Stroke({
                  color: 'white',
                  width: 4,
                }),
                offsetX: -20,
                offsetY: 20,
              }),
              image: new Circle({
                radius: 5,
                stroke: new Stroke({
                  color: this.$_colors.font,
                }),
                fill: new Fill({
                  color: this.$_colors.font,
                }),
              }),
            })
          );
        });
      });
      return styles;
    },
    getCircleMeasurementLabel(radius, statsToShow = ['area', 'length', 'radius']) {
      const stats = {
        area: {
          translation: this.$i18n.t('map.area'),
          fn: getFormatCircleArea,
        },
        length: {
          translation: this.$i18n.t('map.perimeter'),
          fn: getFormatCircleLength,
        },
        radius: {
          translation: this.$i18n.t('map.radius'),
          fn: getFormatCircleRadius,
        },
      };
      return statsToShow.map(stat => `${stats[stat].translation}: ${stats[stat].fn(radius)}`).join('\n');
    },
    getCircleLabelStyle(centerPointCoords, radius, statsToShow, params = {}) {
      return new Style({
        geometry: new Point(centerPointCoords),
        text: new Text({
          backgroundFill: new Fill({
            color: 'rgba(0, 0, 0, 0.6)',
          }),
          text: this.getCircleMeasurementLabel(radius, statsToShow),
          font: 'bold 13px sans-serif',
          fill: new Fill({
            color: 'white',
          }),
          placement: 'point',
          stroke: new Stroke({
            color: 'transparent',
            width: 0,
          }),
          offsetX: 0,
          offsetY: 0,
          padding: [5, 5, 5, 5],
          ...params,
        }),
      });
    },
    getCircleMeasurementStyles({ geometry, feature } = {}) {
      if (!['Circle', 'GeometryCollection'].includes(geometry.getType())) {
        return [];
      }
      const polygon = feature.get('modifyGeometry')?.getGeometries()[0] || geometry.getGeometries()[0];
      const ceontroid = getGeometryCentroid(polygon);
      const point = new Point(ceontroid);
      return [
        new Style({
          geometry: feature => {
            return feature.get('modifyGeometry') || feature.getGeometry();
          },
          fill: new Fill({
            color: 'rgba(237, 234, 183, 0.35)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
        }),
        new Style({
          geometry: point,
          image: new Icon({
            src: '/cursormove.svg',
          }),
        }),
        ...[
          this.getCircleLabelStyle(
            getCenter(polygon.getExtent()),
            getCircleGeometryRadius({
              startCoordinate: getCenter(polygon.getExtent()),
              endCoordinate: polygon.getFirstCoordinate(),
            }),
            undefined,
            { offsetY: 50 }
          ),
        ],
      ];
    },
    getPointMeasurementStyle({ geometry } = {}) {
      if (!(geometry instanceof Point)) {
        return [];
      }
      return [
        new Style({
          image: new Circle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgb(100, 100, 100)',
            }),
            fill: new Fill({
              color: 'rgba(128, 128, 128, 0.8)',
            }),
          }),
        }),
      ];
    },
  },
  mounted() {
    this.getCongestionManholesText = memoizer(this.getCongestionManholesText);
  },
};
