<template>
  <div id="map" class="map" @mouseleave="onMapMouseLeave">
    <span
      v-if="isOverviewMapModuleEnabled"
      class="overview-map-button_wrapper"
      :class="{ 'overview-hidden': isQuickPrint }"
    >
      <table-button
        name="overview-map-button"
        is-context
        @click="toggleMapOverview"
        :tooltip="isOverviewMapVisible ? $i18n.t('button.collapseOverviewMap') : $i18n.t('button.expandOverviewMap')"
        :tooltipProps="{ top: true }"
        :icon="isOverviewMapVisible ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'"
      />
    </span>
  </div>
</template>

<script>
import { get, sync, call } from 'vuex-pathify';

import { default as Map } from '../plugins/new_map';
import { Vector as VectorLayer, Group as LayerGroup, VectorTile as VectorTileLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { transform } from 'ol/proj';
import { GeoJSON } from 'ol/format';
import { createStringXY } from 'ol/coordinate';
import { ScaleLine, MousePosition } from 'ol/control';
import { DragBox, MouseWheelZoom, defaults as defaultInteractions } from 'ol/interaction';
import { unByKey } from 'ol/Observable';
import { Point, Polygon, LineString, GeometryCollection } from 'ol/geom';
import Feature from 'ol/Feature';
import { circular } from 'ol/geom/Polygon.js';
import { getDistance } from 'ol/sphere.js';

import { writeGeometryToGeoJSON, getGeometryCentroid } from '@/assets/js/mapUtils';
import selectedFeatures from '@/mixins/selectedFeatures';
import filters from '@/mixins/filters';
import hydrants from '@/mixins/hydrants';
import { OverviewMap } from 'ol/control.js';

const mapMixins = import.meta.glob('@/mixins/map/*.js', { eager: true });
const getImportedMapMixins = r => Object.values(r).map(value => value.default);

import TableButton from '@/components/TableButton';

export default {
  /**
    https://vuejs.org/v2/style-guide/#Single-file-component-filename-casing-strongly-recommended
  */
  name: 'TheMap',
  components: {
    TableButton,
  },
  mixins: [...getImportedMapMixins(mapMixins), selectedFeatures, filters, hydrants],
  data: () => ({
    isSaveView: true,
    views: [],
    currentViewIndex: -1,
    layersOnMap: [],
    isOverviewMapVisible: true,
    mapExtentFilterUnwatch: undefined,
  }),
  computed: {
    isSelectionActive: sync('tools/toolStatus@isSelectionActive'),
    isQuickPrint: get('tools/toolStatus@isQuickPrintToolActive'),
    cursorPositionEpsg: get('map/cursorPositionEpsg'),
    scale: sync('map/scale'),
    activeTool: sync('tools/activeTool'),
    isActiveToolNotReplaceable: sync('tools/isActiveToolNotReplaceable'),
    isStreetViewToolActive: sync('tools/toolStatus@isStreetViewToolActive'),
    isGoogleMapsToolActive: sync('tools/toolStatus@isGoogleMapsToolActive'),
    layersFeatures: get('layers/layersFeatures'),
    token: get('authentication/token'),
    currentLayer: sync('layers/currentLayer'),
    layerId: sync('layers/currentLayer@id'),
    currentLayerDataSourceName: get('layers/currentLayer@data_source_name'),
    currentLayerDataSourceGeometryName: get(
      'layers/metadata@:currentLayerDataSourceName.attributes_schema.geometry_name'
    ),
    isUseQualifiedNames: get('layers/metadata@:currentLayerDataSourceName.attributes_use_datasource_qualified_names'),
    idPropertyName: get('layers/metadata@:currentLayerDataSourceName.attributes_schema.id_name'),
    isNextViewDisabled: sync('tools/isNextViewDisabled'),
    isPreviousViewDisabled: sync('tools/isPreviousViewDisabled'),
    layersFilters: get('layers/layersFilters'),
    currentProjectId: get('layers/project.id'),
    isOverviewMapModuleEnabled: get('admin/additionalModules@OVERVIEW_MAP.enabled'),
    isSelectionSpecial() {
      return this.isCutoffRoute;
    },
    routeName() {
      return this.$route.name;
    },
    routeParams() {
      return this.$route.params;
    },
    viewsIndex() {
      const { views, currentViewIndex } = this;
      return {
        views,
        currentViewIndex,
      };
    },
    isPreciseDigitizingButtonActive: sync('edit/isPreciseDigitizingButtonActive'),
    preciseNewFeatureCoordinates: sync('edit/preciseNewFeatureCoordinates'),
    isCtrlPressed: sync('tools/isPressed@ctrl'),
    mapExtentFilter: sync('table/mapExtentFilter'),
  },
  watch: {
    isQuickPrint(nV) {
      const action = nV ? 'add' : 'remove';
      this.getControlByName('overviewMapControl')?.element.classList[action]('overview-hidden');
    },
    currentProjectId(nV) {
      if (nV) {
        this.views = [];
        this.currentViewIndex = -1;
      }
    },
    viewsIndex(nV) {
      const { views, currentViewIndex } = nV;
      this.isNextViewDisabled = views?.length === 0 || currentViewIndex === views.length - 1;
      this.isPreviousViewDisabled = views?.length === 0 || currentViewIndex === 0;
    },
    cursorPositionEpsg(nV, oV) {
      this.mousePositionControl.setProjection(nV);
      const { innerText } = this.mousePositionControl.element;
      if (innerText) {
        const currentCoordinates = innerText.split(', ').map(coordinate => +coordinate);
        this.setMousePosition(transform(currentCoordinates, oV, nV));
      }
    },
    center() {
      this.map.getView().setCenter(this.center);
      const map_center = {
        coordinates: this.center,
        crs: {
          properties: {
            name: this.$_config.defaultEpsg,
          },
          type: 'name',
        },
        type: 'Point',
      };
      this.$store.set('layers/SET_PROJECT_CENTER!', map_center);
    },
    zoom() {
      this.map.getView().setZoom(this.zoom);
      this.$store.set('layers/SET_PROJECT_ZOOM!', this.zoom);
    },
    activeTool(nV, oV) {
      if (nV !== oV) {
        this.createGtagEvent({
          action: 'active_tool',
          category: 'map_tools',
          params: nV ? { name: nV, state: 'on' } : { name: oV, state: 'off' },
        });
      }
    },
  },
  methods: {
    createGtagEvent: call('gtag/createEvent'),
    getLayerFeatures: call('layers/getLayerFeatures'),
    getFeaturesLayer: call('layers/getFeaturesLayer'),
    getLayerFeature: call('layers/getLayerFeature'),
    getTile: call('layers/getTile'),
    updateSize() {
      this.$nextTick(() => {
        this.map.updateSize();
      });
    },
    /**
     * Save view to:
     * - local array (this.views) in order to be able to use tools to manually
     * change views (previous and next icon in toolbar),
     * - Vuex in order have it available globally and save it to LS.
     */
    toggleMapOverview() {
      const overviewMapControl = this.getControlByName('overviewMapControl');
      this.isOverviewMapVisible = overviewMapControl.getCollapsed();
      overviewMapControl.setCollapsed(!this.isOverviewMapVisible);
      localStorage.setItem('overviewMapVisible', this.isOverviewMapVisible);
    },
    saveView(map = this.map) {
      const zoom = map.getView().getZoom();
      const center = map.getView().getCenter();
      let rotationDegrees = +(((map.getView().getRotation() || 0) * 180) / Math.PI).toFixed();
      if (rotationDegrees < 0) rotationDegrees += 360;

      this.createGtagEvent({
        action: 'move_end',
        category: 'map_ol',
        params: {
          center_x: center[0],
          center_y: center[1],
          zoom,
        },
      });

      if (!this.isSaveView) {
        this.isSaveView = true;
        return;
      }

      this.center = center;
      this.zoom = zoom;
      if (this.mapExtentFilter) {
        this.extent = JSON.parse(JSON.stringify(map.getView().calculateExtent()));
        if (this.mapExtentFilterUnwatch) this.mapExtentFilterUnwatch();
      }
      if (this.mapRotationModuleEnabled) this.projectMapRotation = rotationDegrees;
      ++this.currentViewIndex;
      if (this.views[this.currentViewIndex]) {
        this.views.splice(this.currentViewIndex);
      }
      this.$set(this.views, this.currentViewIndex, { center, zoom });
    },
    setScale(map) {
      this.scale = this.getScale(map);
    },
    initOverviewMap() {
      if (!this.isOverviewMapModuleEnabled) return;
      this.isOverviewMapVisible = localStorage.getItem('overviewMapVisible')
        ? JSON.parse(localStorage.getItem('overviewMapVisible'))
        : true;
      const overviewMapControl = new OverviewMap({
        className: 'ol-custom-overviewmap',
        layers: [this.getBasemapLayer()],
        tipLabel: '',
        collapsed: !this.isOverviewMapVisible,
        view: this.prepareOverviewMapView(),
      });
      overviewMapControl.set('name', 'overviewMapControl');
      this.map.addControl(overviewMapControl);
    },
    getScale(map) {
      const units = map.getView().getProjection().getUnits();
      const resolution = map.getView().getResolution();
      const INCHES_PER_UNIT = {
        m: 39.37,
        dd: 4374754,
      };
      const DOTS_PER_INCH = 72;
      return Math.round(INCHES_PER_UNIT[units] * DOTS_PER_INCH * resolution);
    },
    // kill me plz
    setMousePosition(coordinates) {
      const transformedString = this.mousePositionControl.values_.coordinateFormat(coordinates);
      this.mousePositionControl.element.innerText = transformedString;
    },
    registerListeners() {
      /**
       * Methods with parameters has to be wrapped in order to not be executed
       * during proccess of attachment.
       */
      this.$root.$on('zoomIn-action', () => {
        this.zoomView(true);
      });
      this.$root.$on('zoomOut-action', () => {
        this.zoomView(false);
      });
      this.$root.$on('nextView-action', () => {
        this.setNextView();
      });
      this.$root.$on('previuosView-action', () => {
        this.setPreviousView();
      });
      this.$root.$on('zoomToFeature', this.zoomToFeature);
      this.$root.$on('zoomToDatasourceFeature', this.zoomToDatasourceFeature);
      this.$root.$on('deleteFeature', this.deleteFeature);
      this.$root.$on('fitView', this.fitView);
      this.$root.$on('removeMarker', this.removeMarker);
      this.$root.$on('createVisibleGeomLayerAndFitView', this.createVisibleGeomLayerAndFitView);
      this.$root.$on('createFeaturesLayer', this.createFeaturesLayer);
      this.$root.$on('clearVisibleGeomLayerAndFitView', this.clearCustomLayer);
      this.$root.$on('defaultView-action', this.setDefaultProjectView);
      this.$root.$on('streetview-action', this.streetviewAction);
      this.$root.$on('googleMaps-action', this.googleMapsAction);
      this.$root.$on('changeLayerVisibility', this.setLayerVisibility);
      this.$root.$on('toggleBasemapLayer', this.toggleBasemapLayer);
      this.$root.$on('toggleFailures', this.toggleFailures);
      this.$root.$on('toggleZDMFailures', this.toggleZDMFailures);
      this.$root.$on('toggleEasements', this.toggleEasements);
      this.$root.$on('toggleFolders', this.toggleFolders);
      this.$root.$on('toggleTasks', this.toggleTasks);
      this.$root.$on('toggleInvestments', this.toggleInvestments);
      this.$root.$on('toggleScada', this.toggleScada);
      this.$root.$on('toggleFailuresFilter', this.toggleFailuresFilter);
      this.$root.$on('toggleZDMFailuresFilter', this.toggleZDMFailuresFilter);
      this.$root.$on('toggleEasementsFilter', this.toggleEasementsFilter);
      this.$root.$on('toggleTasksFilter', this.toggleTasksFilter);
      this.$root.$on('toggleInvestmentsFilter', this.toggleInvestmentsFilter);
      this.$root.$on('toggleScadaFilter', this.toggleScadaFilter);
      this.$root.$on('mvtRefresh', this.refreshMvtLayer);
      this.$root.$on('zoomToSelected', this.zoomToSelected);
      this.$root.$on('attachOnClickSelection', this.attachOnClickSelection);
      this.$root.$on('drawProfileMarker', this.drawProfileMarker);
      this.$root.$on('isFeatureVisible', this.isFeatureVisible);
      this.$root.$on('setLayerStyle', this.setLayerStyle);
      this.$root.$on('quickprint-action', this.quickPrint);
      this.$root.$on('deactivateAllTools', this.deactivateAllToolsHandler);
    },
    toggleModuleIdentification(value) {
      this.$root.$emit('moduleIdentification-action', value);
    },
    attachListener(eventType, callback, { type = 'once', cursor = 'crosshair' } = {}) {
      if (cursor) {
        this.map.getViewport().style.cursor = cursor;
      }
      this.listenerKey = this.map[type](eventType, callback);
    },
    deactivateAllToolsHandler() {
      if (!this.activeTool) {
        return;
      }
      let currentTool = JSON.parse(JSON.stringify(this.activeTool));
      if (currentTool.startsWith('identification')) currentTool = 'identification';
      this.$root.$emit(`${currentTool}-action`, false);
      this.deactivateToolHandler();
    },
    deactivateToolHandler(tool = this.activeTool) {
      if (!tool || tool !== this.activeTool) {
        return;
      }
      this.deactivateTool();
    },
    deactivateTool() {
      unByKey(this.listenerKey);
      this.map.getViewport().style.cursor = '';
      this.activeTool = undefined;
      this.isActiveToolNotReplaceable = false;
    },
    streetviewAction(value) {
      this.deactivateToolHandler();
      if (value) {
        this.attachListener('singleclick', this.openStreetview);
        this.activeTool = 'streetview';
      }
      this.isStreetViewToolActive = value;
    },
    openStreetview(e) {
      const [x, y] = transform(e.coordinate, this.$_config.defaultEpsg, 'EPSG:4326');
      const url = `https://google.com/maps?q&layer=c&cbll=${y},${x}`;
      window.open(url);
      this.deactivateToolHandler();
      this.isStreetViewToolActive = false;

      this.createGtagEvent({
        action: 'open_street_view',
        category: 'map_navbar',
        params: {
          cord_x: x,
          cord_y: y,
        },
      });
    },
    googleMapsAction(value) {
      this.deactivateToolHandler();
      if (value) {
        this.attachListener('singleclick', this.openGoogleMaps);
        this.activeTool = 'googleMaps';
      }
      this.isGoogleMapsToolActive = value;
    },
    openGoogleMaps(e) {
      const [x, y] = transform(e.coordinate, this.$_config.defaultEpsg, 'EPSG:4326');
      const url = `https://google.com/maps/search/?api=1&query=${y},${x}`;
      window.open(url);
      this.deactivateToolHandler();
      this.isGoogleMapsToolActive = false;

      this.createGtagEvent({
        action: 'google_maps_view',
        category: 'map_navbar',
        params: {
          cord_x: x,
          cord_y: y,
        },
      });
    },
    getFeatureByIdAndLayerName(layerName, group, featureId) {
      const layer = this.getLayerById(layerName, group);
      if (!layer || layer instanceof VectorTileLayer) {
        return;
      }
      return layer
        .getSource()
        .getFeatures()
        .find(f => f.getId() == featureId || f.get('id') == featureId);
    },
    deleteFeature(layerName, id) {
      const layer = this.getLayerById(layerName);
      const feature = this.getFeatureFromLayer(layer, id);
      if (!feature) {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('map.featureNotFound', {
            id,
            layer: layerName,
          }),
        });
        return;
      }
      layer.getSource().removeFeature(feature);
    },
    getFeatureFromLayer(layer, featureId) {
      return layer
        .getSource()
        .getFeatures()
        .find(f => f.getId() == featureId || f.get('id') == featureId);
    },
    createVisibleGeomLayerAndFitView(geometry, id, options = {}, layersParams = {}) {
      const olGeometry = new GeoJSON().readGeometry(geometry, {
        dataProjection: geometry.crs.properties.name,
        featureProjection: this.$_config.defaultEpsg,
      });
      this.addCustomLayer(olGeometry, id, layersParams);
      this.fitView(olGeometry, {
        ...options,
        ...(options.isDrawMarker ? { markerGeometry: getGeometryCentroid(olGeometry) } : {}),
      });
    },
    createFeaturesLayer(features, id, options = {}, layersParams = {}, skipFitView = false) {
      const olFeatures = new GeoJSON().readFeatures(features, {
        featureProjection: this.$_config.defaultEpsg || 'EPSG:4326',
        dataProjection: this.$_config.defaultEpsg || 'EPSG:4326',
      });
      this.addCustomLayer(olFeatures, id, layersParams);
      if (skipFitView) return;
      const extent = this.getLayerById(id)?.getSource()?.getExtent();
      if (!extent) return;
      this.fitView(extent, {
        ...options,
        ...(options.isDrawMarker ? { markerGeometry: getGeometryCentroid(extent) } : {}),
      });
    },
    removeMarker(layerId) {
      const feature = this.getFeatureByIdAndLayerName('markersLayer', null, layerId);
      if (!feature) {
        return;
      }
      this.deleteFeature('markersLayer', layerId);
    },
    getMarkersLayer() {
      const newLayer = new VectorLayer({
        id: 'markersLayer',
        isSpecial: true,
        zIndex: 999,
        source: new VectorSource({
          features: [],
        }),
      });
      this.map.addLayer(newLayer);
      return newLayer;
    },
    drawMarker(coordinates, options = {}) {
      const layer = this.getLayerById('markersLayer') || this.getMarkersLayer(options.markerPath);
      this.clearLayer('markersLayer');
      layer.setStyle(this.getMarkerStyle({ path: options.markerPath }));
      const feature = this.getMarkersFeature(coordinates, options.layerId);
      layer.getSource().addFeature(feature);
    },
    getMarkersFeature(coordinates, layerId) {
      const feature = new Feature({ geometry: new Point(coordinates) });
      feature.set('id', layerId);
      return feature;
    },
    drawProfileMarker(geometry, fraction) {
      const coordinates = this.getPointAtLineFraction(geometry.coordinates, fraction);
      this.drawMarker(coordinates, { layerId: 'profile', markerPath: '/marker_red.svg' });
    },
    getPointAtLineFraction(coordinates, fraction) {
      return new LineString(coordinates).getCoordinateAt(fraction);
    },
    async zoomToDatasourceFeature(datasource, id) {
      const feature = await this.getFeature({
        dataSource: datasource,
        feature_id: id,
      });
      if (!feature) {
        this.$store.set('snackbar/PUSH_ERROR!', {
          message: this.$i18n.t('map.featureNotFound', {
            id,
            layer: datasource,
          }),
        });
        return;
      }
      this.fitView(feature.bbox);
    },
    // check if a feature is not filtered out
    async isFeatureVisible(layerName, id, { filters = this.handleGetFilter(), callback }) {
      try {
        const r = await this.getLayerFeatures({
          layer_id: layerName,
          features_filter: filters,
          params: {
            ids_only: true,
            with_geometry: false,
          },
        });
        const isVisible = r.data.data.ids.includes(id);
        await callback(isVisible);
        return isVisible;
      } catch (e) {
        console.log(e);
      }
    },
    async zoomToFeature(layerName, group, id) {
      const layer = this.getLayerById(layerName, group);
      if (layer instanceof VectorTileLayer) {
        const r = await this.getLayerFeature({ layer_id: layerName, feature_id: id });
        const { crs, geometry } = r;
        this.createGeomAndFitView({ ...geometry, crs });
      } else {
        const feature = this.getFeatureByIdAndLayerName(layerName, group, id);
        if (!feature) {
          this.$store.set('snackbar/PUSH_ERROR!', {
            message: this.$i18n.t('map.featureNotFound', {
              id,
              layer: layerName,
            }),
          });
          return;
        }
        this.fitView(feature.getGeometry());
      }
    },
    async getMapView() {
      if (this.isIdentification) {
        const { x, y, z } = this.$_castObjectValuesTypes(this.routeParams, Number);
        this.center = [x, y];
        this.zoom = z;
        return;
      }
      if (this.center && this.zoom) {
        return;
      }
      this.center = transform(
        this.projectMapCenter.coordinates,
        this.projectMapCenter.crs.properties.name,
        this.$_config.defaultEpsg
      );
      this.zoom = this.projectZoom;
    },
    initMap() {
      this.mousePositionControl = new MousePosition({
        projection: this.cursorPositionEpsg,
        undefinedHTML: '',
        className: 'mousePosition',
        target: 'mousePosition',
        coordinateFormat: createStringXY(4),
      });

      const scaleControl = new ScaleLine({
        units: 'metric',
        className: 'scale',
        target: document.getElementById('scale'),
      });

      const mouseScroll = new MouseWheelZoom({
        condition: e => {
          if (e.type !== 'wheel') {
            return;
          }
          if (e.map.getView().getMinZoom() === e.map.getView().getZoom() && e.originalEvent.wheelDelta < 0) {
            this.$store.set('snackbar/PUSH_ERROR!', {
              message: 'map.maxZoomReached',
            });
            return false;
          }
          return true;
        },
      });
      this.map = new Map({
        keyboardEventTarget: document,
        pixelRatio: Math.ceil(window.devicePixelRatio),
        controls: [this.mousePositionControl, scaleControl],
        interactions: new defaultInteractions({
          mouseWheelZoom: false,
        }).extend([mouseScroll]),
        layers: [
          this.getBasemapLayer(true),
          new LayerGroup({
            id: 'layers',
          }),
          new LayerGroup({
            id: 'locksLayers',
          }),
          new LayerGroup({
            id: 'locksResultLayers',
          }),
          new LayerGroup({
            id: 'congestionResultLayers',
          }),
          new LayerGroup({
            id: 'zdmEditorLayers',
          }),
        ],
        target: 'map',
        view: this.prepareView(),
      });

      if (this.mapRotationModuleEnabled) this.rotateMapByDegress(this.projectMapRotation);
      this.key = this.map.on('moveend', e => {
        const { map } = e;
        this.saveView(map);
        this.setScale(map);
      });
      this.mapExtentFilterUnwatch = this.$watch('mapExtentFilter', nV => {
        if (nV) this.saveView();
      });
    },
    setLayerVisibility(data) {
      const layer = this.getLayerById(data.layerId, 'layers');
      layer.setVisible(data.visible);
      this.map.updateSize();
    },
    async zoomToSelected(
      layerId = this.layerId,
      datasource = this.currentLayerDataSourceName,
      featuresId = this.currentLayerSelectedFeatures
    ) {
      try {
        const bbox = await this.getFeaturesBbox(layerId, datasource, featuresId);
        this.fitView(bbox);
      } catch (e) {
        console.log(e);
      }
    },
    async getFeaturesBbox(
      layerId = this.layerId,
      datasource = this.currentLayerDataSourceName,
      featuresId = this.currentLayerSelectedFeatures
    ) {
      try {
        const r = await this.getLayerFeatures({
          features_filter: {
            $IN: {
              [this.isUseQualifiedNames ? this.idPropertyName : `${datasource}.${this.idPropertyName}`]: featuresId,
            },
          },
          layer_id: layerId,
          params: {
            with_collection_bbox: true,
            with_features: false,
          },
        });
        return r.data.data.bbox.map(coords => +coords);
      } catch (e) {
        console.log(e);
      }
    },
    getGeometryAttributeName() {
      return this.isUseQualifiedNames
        ? this.currentLayerDataSourceGeometryName
        : `${this.currentLayerDataSourceName}.${this.currentLayerDataSourceGeometryName}`;
    },
    async defaultSelectionBoxAction(geojsonGeom, name) {
      const projectLayer = this.projectLayers.find(layer => layer.id === this.layerId);
      let legendFilter;
      if (projectLayer?.style) {
        if (!projectLayer?.visible) return { data: { data: { ids: [] } } };
        const minZoom = this.getMinzoomFromStyle(projectLayer.style) + 1;
        const maxZoom = this.getMaxzoomFromStyle(projectLayer.style);
        if (minZoom > this.zoom || maxZoom < this.zoom) return { data: { data: { ids: [] } } };
        legendFilter = this.getLayerStyleFilter();
      }
      const r = await this.getLayerFeatures({
        features_filter: {
          '!AND': [
            {
              '%SPATIALLY_INTERSECTS': {
                [name]: [geojsonGeom],
              },
            },
            ...(this.layersFilters?.[this.layerId] ? [this.layersFilters[this.layerId].filterExpression] : []),
            ...(legendFilter ? [legendFilter] : []),
          ],
        },
        layer_id: this.layerId,
        params: {
          ids_only: true,
        },
      });
      return r;
    },
    async selectFeaturesByLocation(geometry, layerId = this.layerId, geometryName = this.getGeometryAttributeName()) {
      try {
        const geojsonGeom = writeGeometryToGeoJSON(geometry);
        const r = await this.defaultSelectionBoxAction(geojsonGeom, geometryName);
        this.selectFeatures(r.data.data.ids, layerId);
      } catch (error) {
        console.log('error', error);
      }
    },
    async defaultDrawEndSelectionAction(e, geometry, geometryName, layerId, action) {
      try {
        const geojsonGeom = writeGeometryToGeoJSON(geometry);
        const r = await action(geojsonGeom, geometryName);
        this.selectFeatures(r.data.data.ids, layerId);
      } catch (error) {
        console.log('error', error);
      }
    },
    createPolygonFromCircle(coordinates, geometry, projection, createCollection = false, vertices = 64) {
      if (!geometry)
        geometry = createCollection
          ? new GeometryCollection([new Polygon([]), new Point(coordinates[0])])
          : new Polygon([]);
      const center = transform(coordinates[0], projection, 'EPSG:4326');
      const last = transform(coordinates[1], projection, 'EPSG:4326');
      const circle = circular(center, getDistance(center, last), vertices).transform('EPSG:4326', projection);
      if (createCollection) {
        const geometries = geometry.getGeometries();
        geometries[0].setCoordinates(circle.getCoordinates());
        geometry.setGeometries(geometries);
      } else {
        geometry.setCoordinates(circle.getCoordinates());
      }
      return geometry;
    },
    async onDefaultDrawend(action = this.defaultSelectionBoxAction, layerId = this.layerId, e) {
      try {
        if (!this.isCtrlPressed && this.isAnyFeatureSelected) {
          this.clearSelection();
        }
        this.$root.$emit('clearSidebarGeometry');
        await this.defaultDrawEndSelectionAction(
          e,
          e.feature.getGeometry(),
          this.getGeometryAttributeName(),
          layerId,
          action
        );
      } catch (error) {
        console.log('  error', error);
      }
    },
    async createSelection(
      action = this.defaultSelectionBoxAction,
      { geometryName = this.getGeometryAttributeName(), layerId } = {}
    ) {
      const selectionBox = new DragBox({
        className: 'dragBox',
      });
      selectionBox.set('name', 'selectionBox');
      selectionBox.on('boxend', async e => {
        if (
          !e.mapBrowserEvent.originalEvent.ctrlKey &&
          !e.mapBrowserEvent.originalEvent.metaKey &&
          this.isAnyFeatureSelected
        ) {
          this.clearSelection();
        }
        await this.defaultDrawEndSelectionAction(e, selectionBox.getGeometry(), geometryName, layerId, action);
      });
      selectionBox.setActive(true);
      this.map.addInteraction(selectionBox);
    },
    attachOnClickSelection(layerId = this.layerId, isMultiple) {
      this.$root.$emit('deactivateAllTools');
      this.$nextTick(() => {
        this.attachMoveCursorHandler('pointer', layer => layer.get('id') === layerId);
        const layerFilter = layer =>
          layer.get('id') === layerId &&
          (!this.isSelectionSpecial ||
            layer.get('isSpecial') === this.isSelectionSpecial ||
            layer.get('isSpecial') === undefined);
        this.clickSelectionKey = this.map.on('click', async e => {
          const workerFeatures = await this.getWorkerFeaturesAtPixel(e.pixel, layerFilter);
          const workerFeature = workerFeatures[0] ? new Feature(workerFeatures[0]) : null;
          const feature =
            this.map.forEachFeatureAtPixel(e.pixel, f => f, {
              hitTolerance: 5,
              layerFilter,
            }) || workerFeature;
          if (!isMultiple && !e.originalEvent.ctrlKey && !e.originalEvent.metaKey && this.isAnyFeatureSelected) {
            this.clearSelection();
          }
          if (feature) {
            const featureId = feature.get(this.idPropertyName) || feature.getId() || feature.get('id');
            if (this.isFeatureAlreadySelecetd(featureId, this.selectedFeatures[layerId])) {
              this.unselectFeatures([featureId], layerId);
            } else {
              this.selectFeatures([featureId], layerId);
            }
          } else {
            this.$store.set('snackbar/PUSH_MESSAGE!', {
              message: this.$i18n.t('dialog.objectToSelectNotFound'),
            });
          }
        });
        if (this.tableSelectionType) this.isSelectionActive = true;
        this.activeTool = 'selection';
      });
    },
    isFeatureAlreadySelecetd(id, alreadySelected = this.currentLayerSelectedFeatures) {
      return (alreadySelected || []).includes(id);
    },
    selectFeatures(idsArray, layer = this.layerId) {
      this.$store.set('map/ADD_SELECTED_FEATURES!', {
        type: 'selectedFeatures',
        features: {
          [layer]: idsArray,
        },
      });
    },
    unselectFeatures(idsArray, layer = this.layerId) {
      this.$store.set('map/DELETE_SELECTED_FEATURES!', {
        type: 'selectedFeatures',
        features: {
          [layer]: idsArray,
        },
      });
    },
    clearSelection(layer = this.layerId) {
      this.unselectFeatures([], layer);
    },
    toggleBasemapLayer(value) {
      this.map
        .getLayers()
        .getArray()
        .find(layer => layer.get('name') === 'basemapLayer')
        .setVisible(value);
    },
    beforeDestroy() {
      this.map.setTarget(null);
      this.map = null;
      this.$off();
      if (this.scadaThreeRelationDatasourcesInterval) {
        clearInterval(this.scadaThreeRelationDatasourcesInterval);
      }
    },
    setLayerStyle(layerId, styleFunction) {
      const layerOl = this.getLayerById(layerId);
      if (layerOl) {
        layerOl.setStyle(feature => {
          return styleFunction(feature);
        });
      }
    },
    onMapMouseLeave() {
      if (this.isPreciseDigitizingButtonActive && this.preciseNewFeatureCoordinates?.length) {
        this.$root.$emit('setDrawingSidebarInteractionCoordinates', this.preciseNewFeatureCoordinates);
      }
    },
  },
  mounted() {
    this.getMapView();
    this.initMap();
    this.initOverviewMap();
    this.setScale(this.map);
    this.setMousePosition(transform(this.center, this.$_config.defaultEpsg, this.cursorPositionEpsg));
    this.registerListeners();
    this.identifyCoordinatesOnInit();
    this.$once('hook:beforeDestroy', this.beforeDestroy);
  },
};
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 100%;
}
::v-deep .overview-hidden {
  display: none !important;
}
.scale {
  border: 1px solid black;
  border-top: none;
}

::v-deep .dragBox {
  background-color: rgba(63, 127, 191, 0.4);
  border: 2px solid rgba(0, 0, 255, 1);
}

::v-deep {
  .overview-map-button_wrapper {
    position: absolute;
    bottom: 16px;
    left: 16px;
    z-index: 1;
  }
  .ol-custom-overviewmap {
    display: none;
  }
  .ol-custom-overviewmap,
  .ol-custom-overviewmap.ol-uncollapsible {
    bottom: 9px;
    left: 9px;
  }

  .ol-custom-overviewmap:not(.ol-collapsed) {
    border: 1px solid #8f8f8f;
    padding: 0;
    overflow: hidden;
    display: unset;
  }

  .ol-custom-overviewmap .ol-overviewmap-map {
    border: none;
    margin: 0;
    height: 134px;
    width: 233px;
  }

  .ol-custom-overviewmap .ol-overviewmap-box {
    border: 1px solid #ff0000;
    background-color: rgba(#ff0000, 0.1);
  }

  .ol-custom-overviewmap button {
    display: none !important;
  }
}
</style>
